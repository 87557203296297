<template>
  <div>
    <b-row>
      <b-col md="8">
        <div
          :class="$store.state.appConfig.layout.skin == 'dark' ? 'border-dark rounded-lg w-100' : 'bg-white rounded-lg w-100'"
        >
          <div
            class="rounded-top"
            :style="{ 
              backgroundImage: 'url(' + item.cover_image + ')' , 
              backgroundSize: '100% auto',
              backgroundPosition: 'center',
              height: '300px',
              backgroundRepeat: 'no-repeat'
            }"
          ></div>
          <div class="ml-1">
            <p class="mt-1">Category</p>
            <b-row class="w-100">
              <b-col md="8">
                <h3>{{item.name}}</h3>
                <p>{{ item.description }}</p>
              </b-col>
              <b-col md="4" class="d-flex justify-content-end">
                <b-link
                  :tooltip="'none'"
                  class="alert-link cutom-alert-link"
                  :to="{ name: 'learning_lesson', params: { id: item.id} }"
                >
                  <b-button class="btn rounded-pill" variant="primary">Assign learning</b-button>
                </b-link>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col md="4">
        <div
          :class="$store.state.appConfig.layout.skin == 'dark' ? 'dark border-dark rounded-lg w-100 p-2 h-100' : 'bg-white rounded-lg w-100 p-2 h-100'"
        >
          <h4 class="mb-2">This module includes:</h4>
          <div class="d-flex mt-3">
            <feather-icon icon="FilmIcon" size="22" />
            <p class="ml-1">{{item.total_duration_hours}} hours of video</p>
          </div>
          <div class="d-flex mt-1">
            <feather-icon icon="FileTextIcon" size="22" />
            <p class="ml-1">1 homework task</p>
          </div>
          <div class="d-flex mt-1">
            <feather-icon icon="StarIcon" size="22" />
            <p class="ml-1">{{item.total_topics}} quizzes</p>
          </div>
          <div class="d-flex mt-1">
            <feather-icon icon="ClipboardIcon" size="22" />
            <p class="ml-1">{{item.total_assessments}} final assessment</p>
          </div>
          <div class="d-flex mt-1">
            <feather-icon icon="ImageIcon" size="22" />
            <p class="ml-1">Certificate</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <div
      :class="$store.state.appConfig.layout.skin == 'dark' ? 'border-dark rounded-lg w-100 p-2 mt-2' : 'bg-white rounded-lg w-100 p-2 mt-2'"
    >
      <h3>Description</h3>
      <p class="mt-1">{{ item.description }}</p>
    </div>
    <div
      :class="$store.state.appConfig.layout.skin == 'dark' ? 'border-dark rounded-lg w-100 p-2 mt-2' : 'bg-white rounded-lg w-100 p-2 mt-2'"
    >
      <h3>What you will learn</h3>
      <b-row>
        <b-col cols="6" v-for="(learn, index) in item.learn" :key="index">
          <div class="d-flex mt-1">
            <div>
              <feather-icon icon="CheckIcon" size="22" />
            </div>
            <p class="ml-1">{{learn}}</p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      :class="$store.state.appConfig.layout.skin == 'dark' ? 'border-dark rounded-lg w-100 p-2 mt-2' : 'bg-white rounded-lg w-100 p-2 mt-2'"
    >
      <h3>Preview module content</h3>
      <div
        :class="$store.state.appConfig.layout.skin == 'dark' ? 'rounded-lg w-100 p-2 mt-2' : 'preview-module rounded-lg w-100 p-2 mt-2'"
      >
        <p class="mb-3">{{item.content}}</p>
        <b-row v-if="item">
          <!-- <b-col sm="6" md="3" lg="2" class="mb-2" v-for="topic in item.topics" :key="topic.id">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div class="topic-container">
                                <div class="topic-image-container cursor-pointer" @click="show(topic)">
                                    <div class="topic-image">
                                        <img v-if="topic.cover_image_path" :src="topic.cover_image_path" alt="" height="100%">
                                        <img v-else src="https://i0.wp.com/picjumbo.com/wp-content/uploads/beautiful-spring-summer-day-in-green-nature-mountains-free-photo.jpg?w=2210&quality=70" alt="" height="100%">
                                        <span class="completed-topic d-flex justify-content-center align-items-center text-light" v-if="topic.status == 'complete'">
                                        </span>
                                        <span class="check-topic d-flex justify-content-center align-items-center" v-if="topic.status == 'complete'">
                                            <feather-icon
                                                icon="CheckIcon"
                                                size="40"
                                                class="font-weight-bolder text-light"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div class="text-center pt-1">
                                    <h5 >
                                        {{topic.name}}
                                    </h5>
                                </div>
                            </div>
                        </div>
          </b-col>-->
          <b-col class="mb-2">
            <b-row>
              <b-col md="8">
                <h3 class="mb-2">Follow the map to complete your quest</h3>
              </b-col>
              <b-col md="4">
                <div class="d-flex justify-content-end">
                  <div
                    @click.prevent="slidePrev"
                    :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'"
                  >
                    <i class="fa-solid fa-arrow-left"></i>
                  </div>
                  <div
                    @click.prevent="slideNext"
                    :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'"
                  >
                    <i class="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <hooper
              :settings="hooperSettings"
              :infiniteScroll="false"
              ref="carousel"
              class="mb-1 w-100 hooper1"
              @slide="updateCarousel"
            >
              <slide
                class="slide"
                v-for="topic in item.topics"
                :key="topic.id"
                style="max-height: 290px"
              >
                <div class="d-flex justify-content-center align-items-center h-100">
                  <div class="topic-container">
                    <div class="topic-image-container cursor-pointer" @click="show(topic)">
                      <div class="topic-image">
                        <img
                          v-if="topic.cover_image_path"
                          :src="topic.cover_image_path"
                          alt
                          height="100%"
                        />
                        <img
                          v-else
                          src="https://i0.wp.com/picjumbo.com/wp-content/uploads/beautiful-spring-summer-day-in-green-nature-mountains-free-photo.jpg?w=2210&quality=70"
                          alt
                          height="100%"
                        />
                        <span
                          class="completed-topic d-flex justify-content-center align-items-center text-light"
                          v-if="topic.status == 'complete'"
                        ></span>
                        <span
                          class="check-topic d-flex justify-content-center align-items-center"
                          v-if="topic.status == 'complete'"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            size="40"
                            class="font-weight-bolder text-light"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="text-center pt-1">
                      <h5>{{topic.name}}</h5>
                    </div>
                  </div>
                </div>
              </slide>
            </hooper>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  VBTooltip,
  BForm,
  BToast,
  BProgress,
  BFormFile
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { size } from "vee-validate/dist/rules";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormFile,
    BToast,
    BProgress,
    Hooper,
    Slide,
    vSelect,
    flatPickr,
    required
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapGetters({
      item: "modules/item",
      courses: "courses/lookups",
      uploadProgress: "app/uploadProgress"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    course_id() {
      return this.$route.query.course_id ? this.$route.query.course_id : null;
    }
  },
  data() {
    return {
      editCoverPhoto: false,
      editCoverVideo: false,
      showToast: false,
      form: {
        name: null,
        course_id: null,
        description: null,
        cover_image: null,
        overview_video: null
      },
      hooperSettings: {
        itemsToShow: 2,
        itemsToSlide: 1,
        centerMode: false,
        mouseDrag: false,
        wheelControl: false,
        infiniteScroll: false,
        breakpoints: {
          500: {
            itemsToShow: 1
          },
          800: {
            itemsToShow: 4,
            pagination: "fraction"
          },
          1000: {
            itemsToShow: 5,
            pagination: "fraction"
          }
        }
      }
    };
  },
  methods: {
    show(topic) {
      console.log("----------------topic", topic);

      this.$router.push({ name: "show_topic", params: { id: topic.id } });
      // this.$router.push({name:'lesson',params:{id:item.id,process:this.moduleDetails.progress}})
    },
    init() {
      this.$store.dispatch("courses/lookups").then(_ => {
        this.form.course_id = this.course_id
          ? this.courses.find(ele => ele.id == this.course_id)
          : null;
        if (this.id) {
          this.$store.dispatch("modules/get", this.id).then(_ => {
            this.form.name = this.item.name;
            this.form.course_id = this.item.course_id;
            this.form.description = this.item.description;
            // this.form.cover_image = this.item.cover_image
            // this.form.overview_video = this.item.overview_video
          });
        }
      });
    },
    slidePrev(event, ss) {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    submit() {
      this.$refs.moduleForm.validate().then(success => {
        if (success) {
          this.showToast = true;
          if (this.id) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              formData.append(key, value);
            }

            const payload = { id: this.id, query: formData };
            this.$store
              .dispatch("modules/put", payload)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                if (error.response) {
                  this.$swal({
                    icon: "error",
                    title: `<h4>${error.response.data.message}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: "#E84185",
                    allowOutsideClick: true
                  });
                }
              });
          } else {
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              formData.append(key, value);
            }
            const payload = { course_id: this.course_id, query: formData };
            this.$store
              .dispatch("modules/put", payload)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                if (error.response) {
                  this.$swal({
                    icon: "error",
                    title: `<h4>${error.response.data.message}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: "#E84185",
                    allowOutsideClick: true
                  });
                }
              });
          }
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
[dir] .custom-file-label {
  border: unset !important;
  background: transparent !important;
  border-bottom: 1px solid #d8d6de !important;
  cursor: pointer !important;
}
input.is-invalid:focus ~ .custom-file-label {
  box-shadow: unset !important;
}

[dir] .custom-file-label::after {
  padding: 0.6rem 1rem !important;
  background-color: transparent !important;
  color: white !important;
}
.custom-file-label::after {
  border-radius: 0.357rem !important;
}

.custom-file-label::after {
  content: "" !important; /* Optional: Customize the text displayed on hover */
  cursor: pointer !important; /* Indicate clickable area */
  /* Add padding or other styles for a custom look */
}
.preview-module {
  background-color: #f7f8fa;
  border: #d8d6de 1px;
}

.hooper {
  height: auto !important;
}

.hooper1 .slide {
  /* background-color: black; */
  position: relative !important;
}
.hooper-slide {
  width: 300px;
}
.nav-arrows {
  background-color: white;
  /* padding: 10px; */
  border-radius: 10%;
  width: 40px;
  height: 40px;
}
.hooper1 .card-title {
  position: absolute !important;
  bottom: 8%;
  left: 13%;
  color: white;
  /* font-size: 1vw; */
}
.hooper1 .card-title h4 {
  color: white;
  margin-bottom: 0;
}
</style>
